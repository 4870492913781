<template>
    <div>
    </div>
</template>

<script>

import Helpers from "@/utilities/Helpers";

import Vue from "vue";

export default {
  
    mixins: [Helpers],

    props: {
        nextUrl: {
            type: String,
            default: "/"
        }
    },

    methods: {
        async validarLogin() {

            let idUser = localStorage.getItem("iduser");

            if (idUser === null || idUser === undefined) {
                idUser = 0;
            }

            const usuarioResult = await this.$store.dispatch("logInModule/UserById", idUser);

            if (usuarioResult.themeColor === 1)  { 
                //dark
                this.$vuetify.theme.dark = true;
            }
            else { 
                // light
                this.$vuetify.theme.dark = false;
            }

            return usuarioResult;
        },

        async validateToken() {
            let routePath = "";

            let token = this.nextUrl.split('=')[1]

            if (token == null || token == undefined || token == "") {
                routePath = this.nextUrl
            }
            else {
                try {
                    let validToken = await Vue.prototype.$vanKirkApi.get(`login/validate-token/${token}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (validToken.status === 200) {
                        this.$router.push({ name: "resetPassword", params: { token: token } });
                    }
                    else {
                        routePath = this.nextUrl
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Invalid token!");
                    this.$router.push({ path: "/login" });
                }
            }

            return routePath;
        },

        async validateCustomerToken() {
            let routePath = "";

            let guid = this.nextUrl.split('=')[1]

            if (guid == null || guid == undefined || guid == "") {
                routePath = this.nextUrl
            }
            else {
                try {
                    let response = await Vue.prototype.$vanKirkApi.get(`service/validate-guid/${guid}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (response.status === 200) {
                        this.$router.push({ name: "trackerPage", params: { guid } });
                    }
                    else {
                        routePath = this.nextUrl
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Invalid token!");
                    this.$router.push({ path: "/login" });
                }
            }

            return routePath;
        },

        async validateProjectToken() {
            let routePath = "";

            let guid = this.nextUrl.split('=')[1]

            if (guid == null || guid == undefined || guid == "") {
                routePath = this.nextUrl
            }
            else {
                try {
                    let response = await Vue.prototype.$vanKirkApi.get(`service/validate-project-guid/${guid}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (response.status === 200) {

                        this.$router.push({ name: "projectSpecifics", params: { guid } });
                    }
                    else {
                        routePath = this.nextUrl
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Invalid Project!");
                    this.$router.push({ path: "/login" });
                }
            }

            return routePath;
        }
    },

    async created() {

        this.showLoading();

        if (location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
        else {
            
            var routePath = this.nextUrl
            
            if (routePath.includes('validate-token')) {
                routePath = await this.validateToken();
            }
            else if (routePath.includes('validate-customer-token')) {
                routePath = await this.validateCustomerToken();
            }
            else if (routePath.includes('validate-project-token')) {
                routePath = await this.validateProjectToken();
            }
            else
            {
                let loginValido = await this.validarLogin();
                
                if (loginValido === null || loginValido === undefined)
                    loginValido = false;

                if (loginValido === false) {
                    this.$router.push("/logout");
                } 
                else {

                    if (routePath.includes('redirectTo')) {
                        routePath = this.nextUrl.split('=')

                        if (routePath.length > 0) {
                            routePath = routePath[1]
                        }
                        else {
                            routePath = this.nextUrl
                        }
                    }

                    routePath = routePath.toString().replaceAll("%2F", "/")
                    
                    Vue.prototype.getRouter.push({
                        path: routePath
                    });
                }
            }
        }

        this.hideLoading();
    }
};
</script>